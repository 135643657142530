$brand-primary: #1983C3;
$brand-info: lighten($brand-primary, 20%);
$brand-warning: #F7B10B;
$brand-success: #6EB122;
$brand-danger: #ED1C24;
$gray-base: #000000;
$gray-dark: lighten($gray-base, 40%);
$gray-light: lighten($gray-base, 80%);
$font-family-sans-serif: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-x-narrow: "";
$font-family-brand: "Idlewild SSm A", "Idlewild SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-weight: 500;
$btn-border-radius-base: 20px;
$well-bg: #EEF1F3;

.green {
	color: #7DFF55;
}
.red {
	color: #E8242D;
}

// https://github.com/lifegadget/ember-cli-bootstrap-sassy
@import 'bootstrap-custom';

footer.main {
	background: #414148;
	a {
		color: #b5b5c0;
	}
}

body {
	padding-top: 50px;
	font-weight: 300;
}

p {
	line-height: 1.7;
}

/* HEADERS */

h1 {
	//font-family: $font-family-brand;
	&.panel-title {
		line-height: 30px;
	}
	font-size: 24px;
}

h2 {
	&.no-top {
		margin-top: 0;
	}
	&.sub {
		margin-top: 7px;
		margin-bottom: 30px;
	}
	&.nav-head {
		font-weight: 500;
		font-size: 14px;
		padding-left: 10px;
		text-transform: uppercase;
	}
	color: lighten($gray-base, 70%);
	font-size: 18px;
}

.image-bullet {
	text-align: center;
	padding: 10px 80px;
}


.logo-wall {
	img {
		max-height: 75px;
		text-align: center;
		margin: 10px auto;
	}
}

.jumbotron {
	background-color: $brand-info;
	color: white;
	h1 {
		font-weight: 200;
	}
	#call a {
		color: white;
	}
	blockquote {
		border: none;
		color: white;
		font-size: 24px;
		font-weight: 200;
		font-style: italic;
		footer {
			color: rgba(255,255,255,0.4);
			font-size: 12px;
			font-weight: 200;
			font-style: normal;
		}
	}
}


.sub-form {
	h2 {
		margin-top: 6px;
		margin-bottom: 30px;
	}
}



/* NAVIGATION */

nav {
	h2 {
		margin-top: 0;
		margin-bottom: 0;
	}
}


.help-block {
	font-weight: 200;
	font-size: 12px;
}

label {
	font-size: 12px;
	font-weight: 500;
}

.well {
	border-radius: 2px;
	padding-left: 40px;
	padding-right: 40px;
}


a.navbar-brand {
	img {
		height: 30px;
		position: relative;
		top: -4px;
	}
}

/* BUTTONS */

.pointer {
	cursor: pointer;
}

.btn {
	font-size: 12px;
	padding-left: 15px;
	padding-right: 15px;
	&.btn-lg {
		padding-left: 40px;
		padding-right: 40px;
		border-radius: 20px;
	}
	font-family: $font-family-brand;
	&.panel-title {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 12px;
	}
}

/* layout helpers */
.zero-pad {
	padding: 0;
}
.zero-pad-left {
	padding-left: 0;
}
.zero-pad-right {
	padding-right: 0;
}

/* components */

.boxed {
	border: 1px solid #eeeeee;
	border-radius: 1px;
	box-shadow: none;
	&.dark {
		background-color: #444;
		border-color: #666;
		color: #ddd;
	}
}

.input-group-addon {
	&.dark {
		background-color: #444;
		border-color: #666;
		color: #777;
	}
}

select {
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
	background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	&.boxed {
			background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	}
}

.relative-time-picker {
	background: #e9e9e9;
	border: 1px solid #e9e9e9;
	border-radius: 2px;
	input {
		border-bottom: 1px solid #e9e9e9;
		border-left: 1px solid #e9e9e9;
		border-right: 1px solid #e9e9e9;
		border-top: none;
	}
	select {
		border: none;
		border-bottom: 1px solid #e9e9e9;
		background: white;
	}
	p {
		margin: 10px 0 10px 13px;
		font-size: 12px;
		color: #777;
	}
}

.jumbotron {
	h1 {
		font-size: 48px; 
		text-align: center;
		font-family: $font-family-sans-serif;
	}
	&.step {
		color: white;
		h1 {
			font-family: $font-family-brand;
			font-size: 24px;
			color: black;
		}
		p {
			font-size: 16px;
			&.bigger{
				font-size: 18px;
			}
		}
	}
}

ol.step-progress {
	list-style: none;
	font-weight: 500;
	li {
		color: #aaa;
		background-color: #ddd;
		border-radius: 50%;
		font-size: 20px;
		width: 48px;
		height: 48px;
		line-height: 48px;
		margin-right: 15px;
		&.active {
			background-color: $brand-info;
			color: white;
		}
	}
}

.project-list {
	&.panel-body {
		padding: 0;
	}
	.list-group {
		margin: 0;
	}
	.list-group-item {
		cursor: pointer;
		border-left: none;
		border-right: none;
		&:first-child, &:last-child {
			border-radius: 0;
		}
		&:first-child {
			border-top: none;
		}
		&:last-child{
			border-bottom: none;
		}
	}
}

.project-sparkline {
	line-height: 60px;
	font-size: 16px;
	cursor: pointer;
	.name {
		font-weight: 500;
		color: $brand-primary;
	}
	.count {
		font-weight: 800;
		color: $gray-dark;
		font-family: $font-family-brand;
		font-size: 14px;
		.a {
			color: $gray-light;
		}
	}
}


.query-builder {
	h3 {
		font-size: 12px;
		font-weight: 500;
	}
}


.db-contain {
	background: #333;
	border: 1px solid #666;
	border-radius: 1px;
	h3 {
		color: #aaa;
		font-weight: 200;
		margin-left: 25px;
		font-size: 120%;
		margin-bottom: 20px;
	}
	.db-row {
		margin-left: 10px;
		margin-right: 10px;
		padding-bottom: 40px;
	}
	.db-edit-box {
		background: #666;
	}
}



.qr-box {
	width: 100%;
	height: 300px;
	border: 1px solid white;
	border-radius: 5px;
	margin-bottom: 5px;
	&.count {
		border-color: $brand-info;
		display: table;
		background: $brand-info;
		color: white;
		text-align: center;
		.c-contain {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			margin: 1em auto;
			.c-count {
				font-size: 28px;
				font-weight: 900;
				font-family: $font-family-brand;
			}
			.c-label {
				font-weight: 200;
			}
			.c-compare {
				font-weight: 500;
			}
			.c-period {
				font-weight: 200;
				font-size: 10px;
			}
		}
	}
}

.explore-results {
	width: 100%;
	height: 375px;

	.explore-count {
		background: $brand-info;
		height: 100%;
		border: 1px solid white;
		border-radius: 5px;
		color: white;
		font-size: 48px;
		font-weight: 900;
		font-family: $font-family-brand;
		text-align: center;
		padding-top: 110px;
	}
	.explore-start {
		h1 {
			font-weight: 200;
			margin: 90px 40px;
			padding: 20px;
			font-size: 36px;
			color: white;
			background: $brand-info;
			text-align: center;
			opacity: 0.3;
			border: 1px solid $brand-info;
			border-radius: 5px;
		}
	}
}





